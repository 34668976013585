.loader {
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 4 */

#loader-4 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 5px;
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  #loader-4 span {
    width: 15px;
    height: 15px;
  }
}

#loader-4 span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
  -webkit-animation: opacitychange 1s ease-in-out infinite;
}

#loader-4 span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
}

#loader-4 span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}
