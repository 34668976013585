:root,
html {
  touch-action: pan-x pan-y;
  -webkit-tap-highlight-color: transparent;

  /* Disable overscrolling */
  overflow: hidden;
  height: 100%;
}
body {
  /* Disable overscrolling */
  height: 100%;
  overflow: auto;

  overflow-x: hidden;
  touch-action: pan-x pan-y;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  padding-top: env(safe-area-inset-top);
}
html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 7px;
}

.scrollbar-w7::-webkit-scrollbar {
  width: 7px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.no-scrollbars {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.home-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #ffff;
  -webkit-box-shadow: inset 0 0 6px #fff;
}
.no-scrollbars::-webkit-scrollbar {
  display: none;
}
.sticky-cross {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
}
.gradient {
  background: #013a62;
}
.text-instagram {
  color: #c32aa3 !important;
}
.text-brand {
  color: #013a62;
}
.text-metalic {
  color: #9d5f36;
}
.bg-secondary {
  background-color: #f6f6f6;
}
.bg-white-full {
  background-color: #fff;
}
.bg-gray-full {
  background-color: #e5e5e5;
}
.bg-gray-410 {
  background-color: #bdbdbd;
}
.border-primary {
  border: 1px solid #033a64;
}
.border-text-brand-blue {
  border-color: #033a64;
}
.bg-green-full {
  background-color: #219653;
}
.bg-yellow-full {
  background-color: #f2c94c;
}
.bg-instagram {
  background-color: #c32aa3;
}
.indicator {
  border-bottom: 2px solid #033a64;
  -webkit-transition: border 0.5s ease;
  transition: border 0.5s ease;
}
.radius-tl-tr {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.radius-tl-bl {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.radius-20 {
  border-radius: 20px;
}
.radius-30 {
  border-radius: 30px;
}
button:focus,
button[type='button']:focus {
  outline: none;
  border: none !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button:disabled,
button:disabled:hover {
  cursor: not-allowed;
  pointer-events: all !important;
  background: rgba(196, 196, 196, 0.2);
  border: 1px solid rgba(74, 85, 104, 0.1);
  color: rgba(74, 85, 104, 0.5);
}
input[type='button']:focus,
input[type='button']:active {
  outline: none;
  border: none;
}
*:focus,
*:focus *:active,
*:active {
  outline: none;
}
.align-self-end {
  align-self: end;
}
input {
  -webkit-user-select: text !important; /* Chrome, Opera, Safari */
  -moz-user-select: text !important; /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */
}
select.custom-select {
  background: #ffffff
    url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.swal-button {
  text-transform: capitalize;
}
.swal-button--confirm {
  background-color: #013a62 !important;
}
.swal-button--danger {
  background-color: #e64942 !important;
}
.swal-title,
.swal-text,
.swal-button--cancel {
  color: #000000 !important;
}
.swal-text {
  text-align: center !important;
  padding-top: 1rem !important;
}
/* PHONE INPUT */
.flag-dropdown,
.selected-flag {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.phone-order,
.selected-flag {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.user-profile-input .phone-order,
.flag-dropdown,
.selected-flag {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.css-yk16xz-control {
  padding: 0.8rem;
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
}
div.fit-padding > .css-yk16xz-control {
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
}
.filter {
  filter: blur(4px);
}

/* GOOGLE MAPS */
.front {
  fill: #0033ff;
  opacity: 0.9;
}
#back {
  fill: transparent;
  stroke: #0033ff;
  opacity: 0.7;
}

/* START LOADER */
.custom-loader {
  top: 45%;
  left: 7%;
}
/* END LOADER */

/* CHATBOT WINDOW */
.message_received {
  display: table;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 26px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* LIGHTBOX IMAGES */
.lightbox,
.lightbox-img {
  width: 500px;
  height: 500px;
}

/* MAIN DASHBOARD STYLES */
.list-bullet {
  min-width: 15px;
  min-height: 15px;
}
.notification-icon i {
  font-size: 5.25rem;
}
.unread-notification {
  top: 15%;
  right: 13px;
}

/* CREATE NEW SHOP STYLES */
.overlay-wrapper-create {
  height: 95vh;
  overflow-y: auto;
}
.overlay-product-wrapper {
  height: 86vh;
}
.timeline-point {
  min-width: 30px;
  min-height: 30px;
  background-color: #c4c4c4;
}
.timeline-text {
  bottom: 4px !important;
}
.top-6\/8 {
  top: 6.8%;
}
.top-6\/1 {
  top: 6.1%;
}
.top-12 {
  top: 12%;
}
.top-13 {
  top: 13%;
}
.top-15 {
  top: 15%;
}
.top-18 {
  top: 18%;
}
.top-20 {
  top: 20%;
}
.top-50 {
  top: 50%;
}
.textarea-emoji {
  bottom: 5px;
  top: 3rem;
  left: 94%;
}

/* CART ICON */
.cart-shopping {
  box-shadow: rgb(0 0 0 / 16%) 0px 21px 36px;
}
.cart-shopping::before {
  content: '';
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: -17px;
  z-index: -1;
  border: 4px solid #053a67;
  border-bottom: none;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  transform: translateX(40%);
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
}

/* TAGS INPUT */
div[data-radium='true'] {
  border: none !important;
}

/* STEPS 3 IN REGISTRATION - MAP ACTIVITY POPUP CLOSE BUTTON */
.popup-close-button {
  position: relative;
  top: -17px;
  left: 12px;
}

/* PLACEHOLDER CUSTOMIZATION */
.capitalize-placeholder::-webkit-input-placeholder {
  text-transform: capitalize;
}
.capitalize-placeholder:-moz-placeholder {
  /* Firefox 18- */
  text-transform: capitalize;
}
.capitalize-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: capitalize;
}
.capitalize-placeholder:-ms-input-placeholder {
  text-transform: capitalize;
}

/* CAPITALIZE FIRST LETTER */
.capitalize-first::first-letter {
  text-transform: capitalize;
}

/* EMPTY ORDERS STYLE STARTS */
.local-mall-left {
  transform: translate(10px, 0px);
}
.local-mall-right {
  transform: translate(-20px, 10px);
}
.local-mall-right-5 {
  transform: translate(-8px, 2px);
}
/* EMPTY ORDERS STYLE ENDS */

/*______________ Tabs ___________*/
.bottom-menu-item {
  padding: 0.75rem;
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .bottom-menu-item {
    padding-bottom: env(safe-area-inset-bottom) !important;
  }
  .sticky-cross {
    position: -webkit-sticky !important;
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    /* @media only screen and (-webkit-device-pixel-ratio: 2) {
      .bottom-menu-item {
        padding-bottom: 0.75rem !important;
      }
    } */

    /* iphone 6, 6s, 7, 8 - iPhone 7/8 */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      .bottom-menu-item {
        padding-bottom: 0.75rem !important;
      }
    }

    /* iphone 6+, 6s+, 7+, 8+ - iPhone 7+/8+ */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      .bottom-menu-item {
        padding-bottom: 0.75rem !important;
      }
    }

    /* 2688x1242px at 458ppi - iPhone Xs */
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
      .bottom-menu-item {
        padding-bottom: env(safe-area-inset-bottom) !important;
      }
    }

    /* 2436x1125px at 458ppi - iPhone X */
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      .bottom-menu-item {
        padding-bottom: env(safe-area-inset-bottom) !important;
      }
    }

    /* 1792x828px at 326ppi - iPhone XR */
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
      .bottom-menu-item {
        padding-bottom: env(safe-area-inset-bottom) !important;
      }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 3) {
      .bottom-menu-item {
        padding-bottom: calc(
          0.5rem + env(safe-area-inset-bottom)
        ) !important;
      }
    }
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .bottom-menu-item {
    padding: 0.75rem;
  }
}

@supports (padding: max(0px)) {
  .bottom-menu-item {
    padding-bottom: max(
      0.75rem,
      env(safe-area-inset-bottom)
    ) !important;
  }
}
.tabs .tab {
  position: relative;
  z-index: 20;
}
.tabs .tab > div.bg-current {
  position: relative;
}
.tabs .tab > div.bg-current::after {
  content: '';
  height: 19px;
  width: 15px;
  background: #ffffff;
  position: absolute;
  z-index: -3;
  right: 0%;
  top: -31%;
}
.tabs .tab > div.bg-current i::after {
  content: '';
  height: 16px;
  width: 17px;
  background: #053a67;
  position: absolute;
  right: 0%;
  top: -34%;
  z-index: -2;
  border-bottom-right-radius: 30px;
}
.tabs .tab > div.bg-current::before {
  content: '';
  height: 21px;
  width: 25px;
  background: #053a67;
  position: absolute;
  right: 0%;
  bottom: -44.2%;
  border-top-right-radius: 30px;
}
.tabs .tab > div.bg-current i::before {
  content: '';
  height: 19px;
  width: 19px;
  background: #ffffff;
  position: absolute;
  right: 0%;
  bottom: -39%;
  z-index: -1;
}
/* ______________ End Tabs __________________ */

/* _______________ START ORDER ACTIVITY STYLE ________*/
#l:checked ~ .sizeLabel:nth-child(1) {
  background-color: #033a64;
  color: #fff;
}
.order-item-description {
  max-width: 10rem;
}
/* _______________ END ORDER ACTIVITY STYLE __________*/

/* Customize the label (the container) */
.custom-wrapper,
.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-wrapper input,
.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create shapes for radio and checkbox*/
.custom-wrapper .checkmark,
.checkbox-wrapper .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(182, 175, 175);
}

.custom-wrapper .checkmark {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-wrapper:hover input ~ .checkmark,
.checkbox-wrapper:hover ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-wrapper input:checked ~ .checkmark,
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #013a62;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-wrapper input:checked ~ .checkmark:after,
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-wrapper .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* SMALL RADIO BUTTON */
.small-radio-style .custom-wrapper .checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
}

.small-radio-style .checkmark {
  height: 15px;
  width: 15px;
}

/* END CUSTOM RADIO BUTTON */
.border-b-color {
  border-bottom-color: gray;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.inline-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* CAROUSEL PACKAGE */
.QmVdP,
.dAJzKq,
.kzPUbE,
.kbDIuN {
  box-shadow: 0 0 1px 3px #013a62 !important;
  background-color: #013a62 !important;
}
.geDzqp,
.jAmbxV,
.oUxiw {
  background-color: #e5e5e5 !important;
}
.fsXVjr {
  background-color: transparent !important;
}
.gEIJqc:hover:enabled,
.gEIJqc:focus:enabled,
.geDzqp:hover:enabled,
.geDzqp:focus:enabled,
.jAmbxV:hover:enabled,
.jAmbxV:focus:enabled,
.oUxiw:hover:enabled,
.oUxiw:focus:enabled {
  color: #fff;
  background-color: #013a62 !important;
  box-shadow: 0 0 2px 0 #333;
}
.gEIJqc:focus,
.jAmbxV:focus .oUxiw:focus {
  outline: none !important;
}
/* CHANGE ARROWS STYLE */
.fptlcG,
.gEIJqc,
.jAkCdf {
  --bg-opacity: 1;
  background-color: #86929e;
  background-color: rgba(237, 242, 247, 1) !important;
}
/* MARKETING CARDS */
.options-card .rec-carousel {
  height: 400px !important;
}
.advertise-card .rec-carousel {
  height: 400px !important;
}

/* SHOP ACCOUNT ACTIVITY STYLES START */
.shop-card {
  min-height: 460px;
}
.member-shop-card .rec-carousel {
  height: 317px !important;
}
.pending-card .rec-carousel {
  height: 234px !important;
}
.circle-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}
.circle-img-sm {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.circle-img-xs {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
/* SHOP ACCOUNT ACTIVITY STYLES END */

/* ITEMS CARDS STYLES */
.posts-item-card .rec-carousel {
  min-height: 272px;
}
/* ITEMS CARDS STYLES */

/* ADS PROMOTION CARD STARTS */
.promotion-card {
  width: 190px;
}
/* ADS PROMOTION CARD ENDS */

/* DASHBOARD CHANNELS STYLES */
.channel-card .rec-carousel {
  height: 434px !important;
}
/* DASHBOARD CHANNELS STYLES */

.header-main {
  width: calc(78%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
#dashboard-main {
  border-radius: 20px;
}
.dukas {
  border-radius: 30px;
  min-width: 20rem !important;
  min-height: 20rem !important;
}
.broadcast-template {
  width: 18rem !important;
  height: 20rem !important;
}
.broadcasters {
  min-width: 250px !important;
  min-height: 360px !important;
}
.broadcasters-button {
  bottom: 5%;
}
.search-left {
  overflow: auto;
  white-space: nowrap;
}
.post-card {
  border-radius: 15px;
  width: 20rem;
  height: 500px;
}
.item-image {
  height: 30rem;
}

.notfound-text {
  font-weight: 900;
  font-size: 252px;
}
.input-trans {
  flex: 1;
  transition: flex 0.5s;
}
.notification-window {
  top: 2%;
  right: 2%;
}

/* BANNER CAROUSEL */
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -7%;
  left: 0;
  right: 0;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #ffe27a;
}
.carousel-bullet {
  font-size: 5rem;
}

/* TOOLTIPS START */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  min-width: 160px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/* TOOLTIPS END */

/* SWITCH STARTS */
/* The switch - the box around the slider */
.switch,
.small-switch {
  position: relative;
  display: inline-block;
  width: 40px;
}
.small-switch {
  width: 35px !important;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input,
.small-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider,
.small-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 2px solid #053a67;
}

.slider:before,
.small-slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  border: 2px solid #053a67;
}
.small-slider:before {
  height: 16px !important;
  width: 16px !important;
}

input:checked + .slider,
input:checked + .small-slider {
  background-color: var(--background-checked);
  border: 0;
}

input:checked + .slider:before,
input:checked + .small-slider:before {
  border: 0;
  left: -2px;
  bottom: 2px;
}

input:checked + .small-slider:before {
  left: -3px;
  bottom: 1.8px;
}

input:checked + .slider:before,
input:checked + .small-slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round,
.small-slider.round {
  border-radius: 34px;
}

.slider.round:before,
.small-slider.round:before {
  border-radius: 50%;
}
/* SWITCH ENDS */

.checkout-window-fixed {
  width: 100%;
}

@media (max-width: 1024px) {
  .reset-height {
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .notfound-text {
    font-size: 150px;
    font-weight: 800;
  }
  .lego-wrapper {
    height: 100px;
    width: 100px;
  }
  .item-carousel .rec-carousel {
    height: 200px !important;
  }
  .broadcasters {
    min-height: 300px !important;
    margin: 1rem 0;
  }
  .header-main {
    width: calc(73%);
  }

  .tabs .tab > div > i {
    font-size: 27px;
  }
  .tabs .tab > div.bg-current::after {
    content: '';
    height: 9px;
    width: 15px;
    background: #fff;
    position: absolute;
    z-index: -3;
    right: -4%;
    top: -11%;
  }
  .tabs .tab > div.bg-current i::after {
    content: '';
    height: 9px;
    width: 15px;
    background: #053a67;
    position: absolute;
    right: 0%;
    top: -14%;
    z-index: -2;
    border-bottom-right-radius: 30px;
  }

  .tabs .tab > div.bg-current i::before {
    content: '';
    height: 18px;
    width: 9px;
    background: #fff;
    position: absolute;
    right: 0%;
    bottom: -12%;
    z-index: -1;
  }

  .tabs .tab > div.bg-current::before {
    content: '';
    height: 10px;
    width: 10px;
    background: #053a67;
    position: absolute;
    right: 0%;
    bottom: -15%;
    border-top-right-radius: 30px;
  }
  .search-left {
    width: calc(90%);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .search-left::-webkit-scrollbar {
    display: none;
  }
  .post-card {
    min-width: 17rem;
    width: 11rem;
    height: 20rem;
  }
  .sm-post-card {
    max-height: 22.5rem !important;
    width: auto !important;
    min-width: auto !important;
  }
  .post-small {
    min-width: 19rem;
    width: 11rem;
    height: 20rem;
  }
  .item-image {
    height: 15rem;
  }
  .banner-img {
    height: 400px !important;
  }

  /* MAIN DASHBOARD OVERLAY STYLES */
  .overlay-wrapper,
  .overlay-wrapper-create {
    height: 80vh;
    overflow: auto;
  }
}

/* MEDIA QUERIES */
@media (max-width: 766px) {
  /* EXISTING BROADCASTING */
  .title-existing {
    height: auto !important;
  }
  .header-main {
    width: calc(93%) !important;
    border-radius: 0% !important;
  }
  .orders-topbar {
    width: calc(86%) !important;
  }
  #dashboard-main {
    border-top-left-radius: 0% !important;
    border-top-right-radius: 0% !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    margin-bottom: calc(
      3.5rem + env(safe-area-inset-bottom)
    ) !important;
  }
  /* END EXISTING BROADCASTING */

  /* ORDER CAROUSEL */
  .orders-carousel-wrapper {
    height: auto;
  }
  .orders-tbody {
    max-height: 70vh;
  }
  .orders-carousel-wrapper .sc-AxirZ .kyQlHw .rec .rec-item-wrapper {
    padding: 0 !important;
  }
  .orders-carousel-wrapper .cNRLLS {
    margin: 0 !important;
  }

  /* ANIMATION */
  .sm-delay-down-0\/2 {
    animation: slideDown 0.2s linear;
  }

  /* START LOADER */
  .custom-loader {
    top: 46%;
    left: -4%;
  }
  /* END LOADER */

  /* CAROUSEL */
  .carousel-item {
    height: auto !important;
  }
  .carousel-indicators {
    bottom: 0% !important;
  }
  /* LIGHTBOX IMAGES */
  .lightbox,
  .lightbox-img {
    width: 20rem;
    height: 20rem;
  }
  .lightbox-md-skippers {
    width: 80%;
  }
  /* HEADERS */
  #nav-content > ul > li > span {
    color: #013a62;
  }
  span.link {
    color: #053a67;
    font-size: 18px;
  }
  .tabs .tab > div.bg-current::after,
  div.bg-current i::before,
  div.bg-current i::after,
  div.bg-current::before {
    display: none;
  }

  .dukas {
    width: 100% !important;
    height: 16rem !important;
  }
  .tabs .tab > div.bg-current::after {
    content: '';
    height: calc(13px + env(safe-area-inset-bottom));
    width: 26px;
    display: block !important;
    background: #fff;
    position: absolute;
    z-index: -3;
    right: -15%;
    top: 88%;
  }
  .tabs .tab > div.bg-current::before {
    height: calc(9px + env(safe-area-inset-bottom));
    display: block;
    width: 15px;
    background: #053a67;
    position: absolute;
    right: -30%;
    bottom: -4%;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0px;
  }
  .tabs .tab > div.bg-current i::before {
    content: '';
    height: 7px;
    width: 10px;
    background: #fff;
    position: absolute;
    display: block !important;
    left: -16%;
    bottom: 0px;
    z-index: -1;
  }

  .tabs .tab > div.bg-current i::after {
    content: '';
    height: 11px;
    width: 10px;
    background: #053a67;
    position: absolute;
    display: block;
    left: -19%;
    top: 85%;
    z-index: -1;
    border-bottom-right-radius: 30px;
  }

  .broadcast-template {
    min-width: 17rem;
    width: 11rem;
  }

  /* GOOGLE MAPS */
  .mapContainer,
  .mapContainer-wrapper {
    height: 70vh !important;
  }
  .mapContainer-wrapper {
    position: fixed !important;
  }
  .search-location-input {
    display: flex;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #d8d8d8;
  }

  .search-location-input:focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

  .search-location-input:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }

  .search-location-input input {
    height: 32px;
    width: 100%;
    padding: 15px 12px;
    border: none;
    font-size: 16px;
    border-radius: 24px;
  }

  .search-location-input input:focus {
    outline: none;
  }

  /* BORDER RADIUS */
  .sm-radius-full {
    border-radius: 50% !important;
  }
  .sm-radius-none {
    border-radius: 0 !important;
  }
}

@media (max-width: 360px) {
  .dukas {
    height: 15rem !important;
  }
  .order-item-image {
    width: 6rem !important;
  }
  .order-item-body {
    max-height: 80vh !important;
  }
}

@media (max-width: 320px) {
  .order-item-description {
    max-width: 9rem;
  }
}

/* MEDIA QUERIES FOR LEFT PANEL */
@media (min-width: 768px) and (max-width: 1024px) {
  /* MARKETING CARDS */
  .advertise-card .rec-carousel {
    height: 407px !important;
  }
  /* ORDER CAROUSEL */
  .orders-carousel-wrapper {
    height: 500px;
  }

  /* BORDER RADIUS */
  .top-md-12 {
    top: 12%;
  }

  .top-md-5 {
    top: 5%;
  }
  .stats {
    min-width: calc(100vw - 450px);
  }

  /* ------ NEW ORDERS ------ */
  .orders-wrapper {
    max-height: 80vh;
  }
  .tabs .tab > div.bg-current::before {
    content: '';
    height: 13px;
    width: 21px;
    background: #053a67;
    position: absolute;
    right: 0%;
    bottom: -18.2%;
    border-top-right-radius: 30px;
  }

  .tabs .tab > div.bg-current i::before {
    content: '';
    height: 15px;
    width: 19px;
    background: #fff;
    position: absolute;
    right: 0%;
    bottom: -17%;
    z-index: -1;
  }

  .tabs .tab > div.bg-current i::after {
    content: '';
    height: 16px;
    width: 19px;
    background: #053a67;
    position: absolute;
    right: 0%;
    top: -20%;
    z-index: -2;
    border-bottom-right-radius: 30px;
  }

  .tabs .tab > div.bg-current::after {
    content: '';
    height: 15px;
    width: 20px;
    background: #fff;
    position: absolute;
    z-index: -3;
    right: -5%;
    top: -18%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .orders-carousel-wrapper {
    height: 470px !important;
  }
}
@media (min-width: 1024px) {
  .orders-wrapper {
    max-height: 75vh;
  }
  .posts-item-card .rec-carousel {
    height: 503px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1030px) {
  .tabs .tab > div.bg-current::before {
    bottom: -26.2%;
  }
}

@media (min-width: 1024px) {
  .top-lg-13 {
    top: 13% !important;
  }
  .stats {
    min-width: auto;
  }
}

@media (max-width: 767.98px) {
  .overlay-wrapper-create,
  .overlay-product-wrapper {
    height: 100% !important;
    border-radius: 0 !important;
  }
  .radius-sm-0 {
    border-radius: 0 !important;
  }
  .sm-no-scrollbars::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .posts-item-card .rec-carousel {
    height: 482px !important;
  }
  .website-product-card .rec-carousel {
    height: 455px !important;
  }
  .posts-item-card .rec-slider-container {
    margin: 0 !important;
  }
  .channel-card .rec-carousel {
    height: 434px !important;
  }
  .inset-x-sm-4 {
    left: 4%;
    right: 4%;
  }
  .stats {
    min-width: calc(100vw - 80px);
  }
  .notification-window {
    right: 2%;
    left: 2%;
    top: 2%;
  }
  .social-channel-card {
    min-width: calc(100vw - 120px);
  }
  .post-card-wrapper {
    min-width: calc(100vw - 100px);
  }
  /* VISA CARD */
  .rccs__card {
    width: 270px !important;
  }
}

/* ANIMATIONS */
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* ZOOM IN */

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomIn-0\/2 {
  animation: zoomIn 0.2s linear;
}
.delay-zoomIn-0\/3 {
  animation: zoomIn 0.3s linear;
}
.delay-zoomIn-0\/4 {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

/* ZOOM OUT */
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomOut-0\/1 {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomOut-0\/2 {
  animation: zoomOut 0.2s linear;
}
.delay-zoomOut-0\/3 {
  animation: zoomOut 0.3s linear;
}
.delay-zoomOut-0\/4 {
  animation: zoomOut 0.4s linear;
}
.delay-zoomOut-1\/5 {
  animation: zoomOut 1.5s linear;
}
.delay-zoomOut-1 {
  animation: zoomOut 1s linear;
}
.delay-zoomOut-2 {
  animation: zoomOut 2s linear;
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}

/**
        * ----------------------------------------
        * animation scale-up-top
        * ----------------------------------------
        */
.slideUp {
  animation: slideUp 0.5s linear;
}
.delay-up-0\/1 {
  animation: slideUp 0.1s linear;
}
.delay-up-0\/2 {
  animation: slideUp 0.2s linear;
}
.delay-up-0\/3 {
  animation: slideUp 0.3s linear;
}
.delay-up-0\/4 {
  animation: slideUp 0.4s linear;
}
.delay-up-1\/5 {
  animation: slideUp 1.5s linear;
}
.delay-up-1 {
  animation: slideUp 1s linear;
}
.delay-up-2 {
  animation: slideUp 2s linear;
}

@keyframes slideUp {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* SLIDE RIGHT */
.slideRight {
  animation: slideRight 0.5s linear;
}
.delay-right-1 {
  animation: slideRight 1s linear;
}
.delay-right-0\/1 {
  animation: slideRight 0.1s linear;
}
.delay-right-0\/2 {
  animation: slideRight 0.2s linear;
}
.delay-right-0\/3 {
  animation: slideRight 0.3s linear;
}
.delay-right-0\/4 {
  animation: slideRight 0.4s linear;
}
.delay-right-0\/5 {
  animation: slideRight 0.5s linear;
}
.delay-right-1\/5 {
  animation: slideRight 1.5s linear;
}

@keyframes slideRight {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* SLIDE LEFT */
.slideLeft {
  animation: slideLeft 0.5s linear;
}
.delay-left-0\/1 {
  animation: slideLeft 0.1s linear;
}
.delay-left-0\/2 {
  animation: slideLeft 0.2s linear;
}
.delay-left-0\/3 {
  animation: slideLeft 0.3s linear;
}
.delay-left-0\/4 {
  animation: slideLeft 0.4s linear;
}
.delay-left-1\/5 {
  animation: slideLeft 1.5s linear;
}
.delay-left-1 {
  animation: slideLeft 1s linear;
}
.delay-left-2 {
  animation: slideLeft 2s linear;
}
@keyframes slideLeft {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

/**
      * ----------------------------------------
      * animation slide-down
      * ----------------------------------------
      */
.slideDown {
  animation: slideDown 0.5s linear;
}
.delay-down-0\/1 {
  animation: slideDown 0.1s linear;
}
.delay-down-0\/2 {
  animation: slideDown 0.2s linear;
}
.delay-down-0\/3 {
  animation: slideDown 0.3s linear;
}
.delay-down-0\/4 {
  animation: slideDown 0.4s linear;
}
.delay-down-1\/5 {
  animation: slideDown 1.5s linear;
}
.delay-down-1 {
  animation: slideDown 1s linear;
}
.delay-down-2 {
  animation: slideDown 2s linear;
}
@keyframes slideDown {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* loading */
.loading-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/* TOUR GUIDE STYLE */
.introjs-tooltip {
  width: 400px;
  max-width: 400px !important;
  border-radius: 20px !important;
  color: #000;
  padding: 0.75rem;
  font-family: 'Poppins', sans-serif;
}
.introjs-tooltip.hints-style {
  width: auto;
  border-radius: 5px !important;
  box-shadow: 0 3px 8px rgb(33 33 33 / 30%);
}
.introjs-tooltip h1 {
  font-size: 18px;
  font-weight: bold;
  color: #013a62;
}
.introjs-tooltip p {
  font-size: 14px;
  color: #000;
  margin-top: 0.5rem;
}
div.introjs-tooltiptext > p {
  margin: 0.5rem auto;
}
.introjs-arrow {
  height: 18px;
}
.introjs-arrow.left {
  top: 30px !important;
  border-left-color: #fff !important;
}
.introjs-tooltip.tour-tips > .introjs-arrow.bottom-right {
  right: 20px !important;
}
.introjs-tooltip.tour-tips > .introjs-arrow.bottom {
  left: 20px;
}
h1.introjs-tooltip-title {
  color: #828282 !important;
  font-size: 14px;
  font-weight: normal;
}
.introjs-tooltiptext {
  padding: 5px 20px !important;
}
.introjs-tooltipbuttons {
  border-top: none !important;
  padding: 10px 20px !important;
}
a.introjs-button:focus {
  box-shadow: none;
}
a.introjs-button.introjs-nextbutton {
  background: #013a62;
  color: #fff;
  padding: 0.5rem 1.5rem;
  text-shadow: none;
  border: none;
  border-radius: 10px;
}
a.introjs-button.introjs-prevbutton {
  background-color: transparent;
  border: none;
  float: none;
  margin-right: 0.5rem;
  color: #000;
  opacity: 0.8;
  font-weight: 600;
}
div.introjs-tooltipbuttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.hints-style
  > div.introjs-tooltiptext
  > a.introjs-button[role='button'] {
  background: #013a62;
  color: #fff;
  padding: 0.5rem 1.5rem;
  text-shadow: none;
  border: none;
  border-radius: 10px;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .introjs-tooltip {
    width: auto;
  }
  .introjs-arrow {
    height: auto;
  }
  .introjs-arrow.left {
    top: auto;
    border-left-color: transparent;
  }
}
@media only screen and (min-width: 768px) {
  /* CHECKOUT SHOPPING CART WINDOW */
  .checkout-window-fixed {
    width: 420px !important;
  }
}
/* END TOUR GUIDE STYLE */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .scroll-snap-none {
      scroll-snap-type: none;
    }
    .scroll-snap-x {
      scroll-snap-type: x;
    }
    .scroll-snap-x-mandatory {
      scroll-snap-type: x mandatory;
    }
    .scroll-snap-y {
      scroll-snap-type: y;
    }
  }

  .snappy-scroll {
    @apply overflow-x-auto overflow-y-hidden no-scrollbars scrolling-touch scroll-snap-x-mandatory;
  }
}
