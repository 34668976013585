.budgetting-slider {
  height: 3px;
}

.budgetting-value {
  top: 5px;
}

/* CREDIT: https://codepen.io/Manitoba/pen/ZWRjye?js-preprocessor=babel */
[data-slider] {
  position: relative;
  height: 3px;
  border-radius: 10px;
  text-align: left;
  margin: 45px 0 0 0;
}

[data-slider] > div {
  position: absolute;
  left: 13px;
  right: 15px;
  height: 3px;
}

[data-slider] > div > [data-inverse-left] {
  position: absolute;
  left: 0;
  height: 3px;
  border-radius: 10px;
  background-color: #ccc;
  margin: 0 7px;
}

[data-slider] > div > [data-inverse-right] {
  position: absolute;
  right: 0;
  height: 3px;
  border-radius: 10px;
  background-color: #ccc;
  margin: 0 7px;
}

[data-slider] > div > [data-range] {
  position: absolute;
  left: 0;
  height: 3px;
  border-radius: 3px;
  background-color: #033a64;
}

[data-slider] > div > [data-thumb] {
  position: absolute;
  top: -8px;
  z-index: 2;
  height: 20px;
  width: 20px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  background-color: #033a64;
  border-radius: 50%;
  outline: none;
}

[data-slider] > input[type='range'] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 3px;
  top: -1px;
  width: 100%;
  left: 6px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div[data-slider] > input[type='range']::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider] > input[type='range']::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider]
  > input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[data-slider] > input[type='range']:focus {
  outline: none;
}

div[data-slider] > input[type='range']::-ms-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider] > input[type='range']::-moz-range-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider] > input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div[data-slider] > input[type='range']::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[data-slider] > input[type='range']::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div[data-slider] > input[type='range']::-ms-tooltip {
  display: none;
}

[data-slider] > div > [data-sign] {
  opacity: 1;
  position: absolute;
  margin-left: -10px;
  top: -39px;
  z-index: 3;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

[data-slider] > div > [data-sign] > span {
  font-size: 13px;
  font-weight: 700;
  line-height: 28px;
}

div.limiter {
  position: absolute;
  top: -39px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
